<template>
  <div id="app">
    <div
      class="navMenu"
      v-if="$route.path != '/login' && $route.path != '/empty'"
    >
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="" />
        <span>端木软件</span>
      </div>
      <div class="menu">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          router
          active-text-color="#D8522C"
        >
          <el-menu-item index="/">企业网站模板</el-menu-item>
          <el-menu-item index="/applet/index">小程序搭建模板</el-menu-item>
          <el-menu-item index="/aboutUs/index">关于我们</el-menu-item>
          <el-menu-item index="/user/order">我的订单</el-menu-item>
        </el-menu>
      </div>
      <div class="login">
        <div v-if="$store.state.user.user">
          <el-popover placement="bottom" width="300" v-model="visible">
            <div style="margin: 0">
              <p>账号名称：{{ $store.state.user.user.nickname }}</p>
              <p>账号ID：{{ $store.state.user.user.uid }}</p>
              <!-- <el-divider></el-divider>
              <p style="cursor: pointer;" @click="myOrder">
                <i class="el-icon-tickets"></i> 我的订单
              </p> -->
              <el-divider></el-divider>
              <el-button @click="outLogin">退出登录</el-button>
            </div>
            <span slot="reference">控制台</span>
          </el-popover>
          <img :src="$store.state.user.user.avatar" alt="" />
        </div>
        <el-button @click="login" v-else>登录</el-button>
      </div>
    </div>
    <div
      class="aside"
      v-if="$route.path != '/login' && $route.path != '/empty'"
    >
      <div class="title">
        联系我们
        <el-popover
          placement="left-end"
          :offset="-20"
          :visible-arrow="false"
          trigger="hover"
        >
          <div class="wx-contact">
            <img
              style="width: 140px; height: 140px"
              src="@/assets/images/qr-contact.png"
              alt=""
            />
          </div>
          <img slot="reference" src="@/assets/images/wx.png" alt="" />
        </el-popover>
        <!-- <img src="@/assets/images/dh.png" alt="" /> -->
      </div>
    </div>
    <router-view />
    <div
      class="appFooter"
      v-if="
        $route.path != '/login' &&
        $route.path.indexOf('/user') == -1 &&
        $route.path != '/empty'
      "
    >
      <div class="flex-st-cent">
        <p>Copyright @2024 江苏端木软件技术有限公司 保留所有权利.</p>
        <a href="https://beian.miit.gov.cn" target="_blank"
          >苏ICP备16037710号-2</a
        >
      </div>
      <div class="flex-st-cent">
        <span>|</span>
        <img
          src="https://vstecscloud-file-manage.oss-cn-hangzhou.aliyuncs.com/blade-oss/e8f1d3796175477dafc1db2e7719111d@:,_beianan.png"
          alt=""
        />
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011402011335"
          target="_blank"
          >苏公网安备 32011402011335号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapActions } from "vuex";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      activeIndex: "/",
      visible: false,
    };
  },
  mounted() {
    sessionStorage.getItem("user") &&
      this.setUser(JSON.parse(sessionStorage.getItem("user")));
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    login() {
      this.$router.push({ path: "/login" });
    },

    outLogin() {
      this.$axios.get("/api/front/logout", {}).then(() => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
        this.setUser("");
        this.$router.push({ path: "/" });
        this.visible = false;
      });
    },
    myOrder() {
      if (this.$store.state.user.user.avatar) {
        this.$router.push({ path: "/user/order" });
      } else {
        this.$router.push({ path: "/login" });
      }
      this.visible = false;
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "JDZY";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 70px 0 0;
  min-height: 100vh;
  background-color: #f6f6f6;
}
div {
  box-sizing: border-box;
}
.navMenu {
  width: 100%;
  height: 70px;
  min-height: 70px;
  background-color: #fff;
  padding: 0 160px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  z-index: 9999;
  .logo {
    width: 150px;
    min-width: 150px;
    height: 70px;
    line-height: 70px;
    font-weight: 500;
    font-size: 24px;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    img {
      width: 40px;
      height: 40px;
      margin: 0 10px 0 0;
      vertical-align: middle;
    }
    span {
      width: 96px;
      height: 34px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 34px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }

  .menu {
    width: calc(100% - 280px);
    min-width: 680px;
    text-align: center;
    .el-menu-demo {
      height: 70px;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
    }
    .el-menu--horizontal {
      border: none;
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0;
      width: 150px;
      height: 70px;
      line-height: 70px;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .login {
    line-height: 70px;
    min-width: 100px;
    .el-button {
      width: 90px;
      height: 42px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.55);
      padding: 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.55);
      font-style: normal;
      text-transform: none;
    }
    span {
      cursor: pointer;
    }
    img {
      width: 42px;
      height: 42px;
      vertical-align: middle;
    }
  }
}
.container {
  width: 100%;
  min-height: calc(100vh - 321px);
  padding: 0 160px;
  margin-bottom: 200px;
}
.aside {
  width: 68px;
  height: 220px;
  background: #d8522c;
  position: fixed;
  right: 0;
  bottom: 110px;
  z-index: 99;
  border-radius: 110px;
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    width: 20px;
    height: 128px;
    margin: 25px auto 0;
    color: #ffffff;
    text-align: center;
    img {
      margin-top: 20px;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .wx-contact {
      width: 140px;
      height: 140px;
      img {
        width: 140px;
        height: 140px;
      }
    }
  }

  // transform: rotate(180deg);
}
.appFooter {
  // font-family: "PingFang SC-Thin";
  width: 100%;
  height: 50px;
  background: #3a3d42;
  line-height: 50px;
  font-size: 14px;
  color: #939393;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .flex-st-cent {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    p {
      margin-right: 4px;
    }
    span {
      margin-left: 10px;
      margin-right: 10px;
    }
    img {
      width: 20px;
    }
  }
  a {
    color: #939393;
    text-decoration: none;
    margin-left: 4px;
    &:hover {
      text-decoration-line: underline;
    }
  }
}
</style>
