var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.$route.path != '/login' && _vm.$route.path != '/empty')?_c('div',{staticClass:"navMenu"},[_vm._m(0),_c('div',{staticClass:"menu"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","router":"","active-text-color":"#D8522C"}},[_c('el-menu-item',{attrs:{"index":"/"}},[_vm._v("企业网站模板")]),_c('el-menu-item',{attrs:{"index":"/applet/index"}},[_vm._v("小程序搭建模板")]),_c('el-menu-item',{attrs:{"index":"/aboutUs/index"}},[_vm._v("关于我们")]),_c('el-menu-item',{attrs:{"index":"/user/order"}},[_vm._v("我的订单")])],1)],1),_c('div',{staticClass:"login"},[(_vm.$store.state.user.user)?_c('div',[_c('el-popover',{attrs:{"placement":"bottom","width":"300"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticStyle:{"margin":"0"}},[_c('p',[_vm._v("账号名称："+_vm._s(_vm.$store.state.user.user.nickname))]),_c('p',[_vm._v("账号ID："+_vm._s(_vm.$store.state.user.user.uid))]),_c('el-divider'),_c('el-button',{on:{"click":_vm.outLogin}},[_vm._v("退出登录")])],1),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v("控制台")])]),_c('img',{attrs:{"src":_vm.$store.state.user.user.avatar,"alt":""}})],1):_c('el-button',{on:{"click":_vm.login}},[_vm._v("登录")])],1)]):_vm._e(),(_vm.$route.path != '/login' && _vm.$route.path != '/empty')?_c('div',{staticClass:"aside"},[_c('div',{staticClass:"title"},[_vm._v(" 联系我们 "),_c('el-popover',{attrs:{"placement":"left-end","offset":-20,"visible-arrow":false,"trigger":"hover"}},[_c('div',{staticClass:"wx-contact"},[_c('img',{staticStyle:{"width":"140px","height":"140px"},attrs:{"src":require("@/assets/images/qr-contact.png"),"alt":""}})]),_c('img',{attrs:{"slot":"reference","src":require("@/assets/images/wx.png"),"alt":""},slot:"reference"})])],1)]):_vm._e(),_c('router-view'),(
      _vm.$route.path != '/login' &&
      _vm.$route.path.indexOf('/user') == -1 &&
      _vm.$route.path != '/empty'
    )?_c('div',{staticClass:"appFooter"},[_vm._m(1),_vm._m(2)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":""}}),_c('span',[_vm._v("端木软件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-st-cent"},[_c('p',[_vm._v("Copyright @2024 江苏端木软件技术有限公司 保留所有权利.")]),_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("苏ICP备16037710号-2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-st-cent"},[_c('span',[_vm._v("|")]),_c('img',{attrs:{"src":"https://vstecscloud-file-manage.oss-cn-hangzhou.aliyuncs.com/blade-oss/e8f1d3796175477dafc1db2e7719111d@:,_beianan.png","alt":""}}),_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011402011335","target":"_blank"}},[_vm._v("苏公网安备 32011402011335号")])])
}]

export { render, staticRenderFns }