export default {
  state: {
    user: "", // 登录的用户,
    showLogin: false, // 用于控制是否显示登录组件
    showUpdatePw: false,
    addMyAddr: false,
    // 地址列表
    address: [],
    // {
    //     id: 1,
    //     receiver: "陈同学",
    //     receive_mobile: "13580018623",
    //     receive_addr: "广东 广州市 白云区 江高镇 广东白云学院"
    //     is_default: xxx, 是否默认地址
    //     user_id: xxx, 所属用户
    //   },
  },
  getters: {
    //
    getUser (state) {//传入state
      return state.user
    },
    getShowLogin (state) {
      return state.showLogin
    },
    getUpdatePw(state){
      return state.showUpdatePw
    },

    getAddMyAddr(state){
      return state.addMyAddr
    },
    getAddress(state){
      return state.address
    },
  },
  mutations: {
    // 传入第一个参数state, 第二个传入的数据data
    setUser (state, data) {
      state.user = data;
    },
    setShowLogin (state, data) {
      state.showLogin = data;
    },
    setUpdatePw(state, data){
      state.showUpdatePw = data
    },

    setAddMyAddr(state, data){
      state.addMyAddr = data
    },
  },
  actions: {
    //传入的第一个参数是context---->获取{commit}
    setUser ({ commit }, data) {
      commit('setUser', data);
    },
    setShowLogin ({ commit }, data) {
      commit('setShowLogin', data);
    },

    setUpdatePw({commit}, data){
      commit("setUpdatePw", data)
    },

    setAddMyAddr({commit}, data){
      commit("setAddMyAddr", data)
    },
  }
}